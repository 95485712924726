import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useNavigate } from 'react-router-dom';
import Label from '@/components/form/Label/Label';
import SpaceCard from '@/components/reusable/SpaceCard/SpaceCard';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './SpacesList.scss';

const SpacesDataRow = (props) => {
  const { spacesData, goToSpacesPreList } = props;
  const chunkArray = (array, size) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  };

  const groupedSpaces = chunkArray(spacesData || [], 3);
  const containerClass =
    groupedSpaces.length === 1
      ? 'padlr20 gap-20 margb20 t-hide m-hide format100'
      : 'padlr20 gap-20 margb20 t-hide m-hide';

  return (
    <>
      {groupedSpaces.map((group, groupIndex) => (
        <FlexContainer key={groupIndex} className={containerClass} justifyBetween>
          {group.map((space, index) => (
            <SpaceCard
              key={index}
              imgUrl={space?.main_image}
              spaceInfo={space}
              spaceType={space?.type}
              spaceTitle={space?.space_title}
              spaceDescription={space?.space_details}
              handleDetailButton={goToSpacesPreList}
              price={space?.price}
              priceAndCapacityDetails
              wifi
              coffee
              parking
            />
          ))}
          {groupIndex === groupedSpaces.length - 1 &&
            group.length < 3 &&
            Array.from({ length: 3 - group.length }).map((_, emptyIndex) => (
              <FlexContainer
                className={'padlr20 padtb20 flex-column justify-between format100'}
                key={emptyIndex}
              ></FlexContainer>
            ))}
        </FlexContainer>
      ))}
    </>
  );
};

const SpacesList = (props) => {
  const { customTitle, buttonTitle, isShowButton, spacesData } = props;
  const navigate = useNavigate();

  const goToSpacesPreList = () => {
    navigate('/productPreList');
  };
  const goToSpacesList = () => {
    navigate('/spaces');
  };

  return (
    <PageContainer>
      <FlexContainer
        className={'flex-column margt20 margb60 mySpacesList'}
        justifyBetween
        alignItemsStart
      >
        {customTitle && (
          <Label type="Subtitle-1 text-20 bold marglr20 padtb20">{customTitle}</Label>
        )}
        <SpacesDataRow spacesData={spacesData} goToSpacesPreList={goToSpacesPreList} />
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          className="d-hide"
        >
          {spacesData?.length >= 1 &&
            spacesData.map((space, index) => (
              <SwiperSlide key={index}>
                <SpaceCard
                  imgUrl={space?.main_image}
                  spaceInfo={space}
                  spaceType={'Coworking'}
                  spaceTitle={space?.space_title}
                  spaceDescription={space?.space_details}
                  handleDetailButton={goToSpacesPreList}
                  priceAndCapacityDetails
                  wifi
                  coffee
                  parking
                />
              </SwiperSlide>
            ))}
          {!spacesData && (
            <>
              <SwiperSlide>
                <SpaceCard
                  imgUrl={'spaces/espacio-1.png'}
                  spaceType={'Coworking'}
                  spaceTitle={'Espacio 1'}
                  spaceDescription={
                    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s'
                  }
                  handleDetailButton={goToSpacesPreList}
                  priceAndCapacityDetails
                  isMobileStyle
                  wifi
                  coffee
                  parking
                />
              </SwiperSlide>
              <SwiperSlide>
                <SpaceCard
                  imgUrl={'spaces/espacio-2.png'}
                  spaceType={'Coworking'}
                  spaceTitle={'Espacio 2'}
                  spaceDescription={
                    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s'
                  }
                  handleDetailButton={goToSpacesPreList}
                  priceAndCapacityDetails
                  isMobileStyle
                  wifi
                  coffee
                />
              </SwiperSlide>
              <SwiperSlide>
                <SpaceCard
                  imgUrl={'spaces/espacio-3.png'}
                  spaceType={'Coworking'}
                  spaceTitle={'Espacio 3'}
                  spaceDescription={
                    'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry s standard dummy text ever since the 1500s'
                  }
                  handleDetailButton={goToSpacesPreList}
                  priceAndCapacityDetails
                  isMobileStyle
                  coffee
                  parking
                />
              </SwiperSlide>
            </>
          )}
        </Swiper>
        {isShowButton && (
          <FlexContainer className={'flex-column width100'} alignItemsCenter>
            <button
              onClick={goToSpacesList}
              className={
                'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margt50 margb20 padlr40 padtb10'
              }
            >
              {buttonTitle}
            </button>
          </FlexContainer>
        )}
      </FlexContainer>
    </PageContainer>
  );
};

export default SpacesList;
