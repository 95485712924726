import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useCreateSpace, createSpace, updateSpaceMainImage } from '@/services/spacesService';
import { createSpaceSubtypeRelationship } from '@/services/spacesSubtypeRelationshipService';
import { TextField, Grid, Box, Button, Typography, Alert } from '@mui/material';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  space_name: Yup.string().required('Space Name is required'),
  space_address: Yup.string().required('Space Address is required'),
  space_title: Yup.string().required('Space Title is required'),
  main_image: Yup.mixed()
    .required('La imagen es requerida')
    .test('fileSize', 'El archivo es demasiado grande', (value) => {
      return value && value.size <= 15000000;
    })
    .test('fileType', 'El formato no es válido', (value) => {
      return value && ['image/jpeg', 'image/png'].includes(value.type);
    }),
  space_details: Yup.string().required('Space Details are required'),
  host_id: Yup.number().required('Host ID is required'),
  house_number: Yup.number().required('House Number is required'),
  space_latitude: Yup.string().required('Latitude is required'),
  space_logitude: Yup.string().required('Longitude is required'),
  street_reference: Yup.string().required('Street Reference is required'),
  zip_code: Yup.number().required('Zip Code is required'),
  is_reserved: Yup.number().required('Reserved status is required'),
  gmaps_url: Yup.string().url('Invalid URL').required('Google Maps URL is required'),
  subtypeRelationship: Yup.object({
    subtype_id: Yup.number().required('Subtype ID is required'),
    space_details: Yup.string().required('Subtype Space Details are required'),
    host_capacity: Yup.number().required('Host Capacity is required'),
    pet_friendly: Yup.number().required('Pet Friendly status is required'),
    coffe_water: Yup.number().required('Coffee/Water status is required'),
    hourly_rate: Yup.number().required('Hourly Rate is required'),
    rate_4_hours: Yup.number().required('4 Hours Rate is required'),
    rate_8_hours: Yup.number().required('8 Hours Rate is required'),
    state_id: Yup.number().required('State ID is required'),
    parking: Yup.number().required('Parking availability is required'),
    parking_details: Yup.string().required('Parking Details are required'),
  }),
});

const SpaceForm = () => {
  const { mutate } = useCreateSpace();
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = async (values, { resetForm }) => {
    console.log(JSON.stringify(values));
    try {
      //await mutate(values);
      const createdSpace = await createSpace(values);
      if (!createdSpace || !createdSpace.space_id) {
        throw new Error('No se recibió un user_id válido al crear el usuario');
      }
      const spaceId = createdSpace.space_id;
      console.log('User ID obtenido de la respuesta:', spaceId);
      //await updateSpaceMainImage(parseInt(spaceId), values.main_image);

      var subtypeRelationshipValues = values?.subtypeRelationship;
      //subtypeRelationshipValues.space_id = values?.space_id;
      subtypeRelationshipValues.space_id = spaceId;
      console.log('subtype', subtypeRelationshipValues);

      await createSpaceSubtypeRelationship(subtypeRelationshipValues);
      console.log('Espacio creado y/o imagen actualizada con éxito');
      setSuccessMessage('Espacio creado y/o imagen actualizada con éxito');
      resetForm();
    } catch (error) {
      console.error('Error al crear o actualizar el espacio:', error);
      setSuccessMessage('Error al crear o actualizar el espacio');
    }
  };

  return (
    <Formik
      initialValues={{
        space_name: '',
        space_address: '',
        space_title: '',
        main_image: null,
        space_state: 1,
        space_details: '',
        host_id: '',
        house_number: '',
        space_latitude: '',
        space_logitude: '',
        street_reference: '',
        zip_code: '',
        is_reserved: 0,
        gmaps_url: '',
        subtypeRelationship: {
          subtype_id: 1,
          space_details: '',
          host_capacity: '',
          pet_friendly: 0,
          coffe_water: 0,
          hourly_rate: '',
          rate_4_hours: '',
          rate_8_hours: '',
          state_id: 1,
          parking: 0,
          parking_details: '',
        },
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, touched }) => {
        console.log('Valores del formulario:', values);
        console.log('Errores de validación:', errors);
        console.log('Campos tocados:', touched);
        return (
          <Form>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Crear Espacios
            </Typography>

            <Box sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="space_name">
                    {({ field }) => (
                      <TextField
                        label="Space Name"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_name && errors.space_name)}
                        helperText={touched.space_name && errors.space_name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_address">
                    {({ field }) => (
                      <TextField
                        label="Space Address"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_address && errors.space_address)}
                        helperText={touched.space_address && errors.space_address}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_title">
                    {({ field }) => (
                      <TextField
                        label="Space Title"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_title && errors.space_title)}
                        helperText={touched.space_title && errors.space_title}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="main_image">
                    {({ field, form }) => (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          sx={{ marginTop: '10px' }}
                        >
                          Cargar Imagen
                          <input
                            type="file"
                            hidden
                            onChange={(e) => {
                              setFieldValue('main_image', e.currentTarget.files[0]);
                            }}
                            accept="image/*"
                          />
                        </Button>
                        {form.errors.main_image && form.touched.main_image && (
                          <div style={{ color: 'red' }}>{form.errors.main_image}</div>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="space_details">
                    {({ field }) => (
                      <TextField
                        label="Space Details"
                        multiline
                        rows={4}
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_details && errors.space_details)}
                        helperText={touched.space_details && errors.space_details}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="host_id">
                    {({ field }) => (
                      <TextField
                        label="Host ID"
                        fullWidth
                        {...field}
                        error={Boolean(touched.host_id && errors.host_id)}
                        helperText={touched.host_id && errors.host_id}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="house_number">
                    {({ field }) => (
                      <TextField
                        label="House Number"
                        fullWidth
                        {...field}
                        error={Boolean(touched.house_number && errors.house_number)}
                        helperText={touched.house_number && errors.house_number}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_latitude">
                    {({ field }) => (
                      <TextField
                        label="Latitude"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_latitude && errors.space_latitude)}
                        helperText={touched.space_latitude && errors.space_latitude}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="space_logitude">
                    {({ field }) => (
                      <TextField
                        label="Longitude"
                        fullWidth
                        {...field}
                        error={Boolean(touched.space_logitude && errors.space_logitude)}
                        helperText={touched.space_logitude && errors.space_logitude}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="street_reference">
                    {({ field }) => (
                      <TextField
                        label="Street Reference"
                        fullWidth
                        {...field}
                        error={Boolean(touched.street_reference && errors.street_reference)}
                        helperText={touched.street_reference && errors.street_reference}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="zip_code">
                    {({ field }) => (
                      <TextField
                        label="Zip Code"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(touched.zip_code && errors.zip_code)}
                        helperText={touched.zip_code && errors.zip_code}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="is_reserved">
                    {({ field }) => (
                      <TextField
                        select
                        label="Reservado"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.is_reserved && errors.is_reserved)}
                        helperText={touched.is_reserved && errors.is_reserved}
                      >
                        <option value="0">No reservado</option>
                        <option value="1">Reservado</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="gmaps_url">
                    {({ field }) => (
                      <TextField
                        label="Google Maps URL"
                        fullWidth
                        {...field}
                        error={Boolean(touched.gmaps_url && errors.gmaps_url)}
                        helperText={touched.gmaps_url && errors.gmaps_url}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.subtype_id">
                    {({ field }) => (
                      <TextField
                        select
                        label="Subtype"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.subtype_id &&
                            errors.subtypeRelationship?.subtype_id,
                        )}
                        helperText={
                          touched.subtypeRelationship?.subtype_id &&
                          errors.subtypeRelationship?.subtype_id
                        }
                      >
                        <option value="1">Coworking</option>
                        <option value="2">Sala de Juntas</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.state_id">
                    {({ field }) => (
                      <TextField
                        select
                        label="State"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.state_id &&
                            errors.subtypeRelationship?.state_id,
                        )}
                        helperText={
                          touched.subtypeRelationship?.state_id &&
                          errors.subtypeRelationship?.state_id
                        }
                      >
                        <option value="0">Inactivo</option>
                        <option value="1">Activo</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="subtypeRelationship.space_details">
                    {({ field }) => (
                      <TextField
                        label="Subtype Space Details"
                        multiline
                        rows={4}
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.space_details &&
                            errors.subtypeRelationship?.space_details,
                        )}
                        helperText={
                          touched.subtypeRelationship?.space_details &&
                          errors.subtypeRelationship?.space_details
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.host_capacity">
                    {({ field }) => (
                      <TextField
                        label="Host Capacity"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.host_capacity &&
                            errors.subtypeRelationship?.host_capacity,
                        )}
                        helperText={
                          touched.subtypeRelationship?.host_capacity &&
                          errors.subtypeRelationship?.host_capacity
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.pet_friendly">
                    {({ field }) => (
                      <TextField
                        select
                        label="Pet Friendly"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.pet_friendly &&
                            errors.subtypeRelationship?.pet_friendly,
                        )}
                        helperText={
                          touched.subtypeRelationship?.pet_friendly &&
                          errors.subtypeRelationship?.pet_friendly
                        }
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.coffe_water">
                    {({ field }) => (
                      <TextField
                        select
                        label="Coffee/Water"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.coffe_water &&
                            errors.subtypeRelationship?.coffe_water,
                        )}
                        helperText={
                          touched.subtypeRelationship?.coffe_water &&
                          errors.subtypeRelationship?.coffe_water
                        }
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.hourly_rate">
                    {({ field }) => (
                      <TextField
                        label="Hourly Rate"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.hourly_rate &&
                            errors.subtypeRelationship?.hourly_rate,
                        )}
                        helperText={
                          touched.subtypeRelationship?.hourly_rate &&
                          errors.subtypeRelationship?.hourly_rate
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.rate_4_hours">
                    {({ field }) => (
                      <TextField
                        label="4 Hours Rate"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.rate_4_hours &&
                            errors.subtypeRelationship?.rate_4_hours,
                        )}
                        helperText={
                          touched.subtypeRelationship?.rate_4_hours &&
                          errors.subtypeRelationship?.rate_4_hours
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.rate_8_hours">
                    {({ field }) => (
                      <TextField
                        label="8 Hours Rate"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.rate_8_hours &&
                            errors.subtypeRelationship?.rate_8_hours,
                        )}
                        helperText={
                          touched.subtypeRelationship?.rate_8_hours &&
                          errors.subtypeRelationship?.rate_8_hours
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.parking">
                    {({ field }) => (
                      <TextField
                        select
                        label="Parking"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.parking &&
                            errors.subtypeRelationship?.parking,
                        )}
                        helperText={
                          touched.subtypeRelationship?.parking &&
                          errors.subtypeRelationship?.parking
                        }
                      >
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="subtypeRelationship.parking_details">
                    {({ field }) => (
                      <TextField
                        label="Parking Details"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.parking_details &&
                            errors.subtypeRelationship?.parking_details,
                        )}
                        helperText={
                          touched.subtypeRelationship?.parking_details &&
                          errors.subtypeRelationship?.parking_details
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              {successMessage && (
                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                  <Alert severity={successMessage.includes('Error') ? 'error' : 'success'}>
                    {successMessage}
                  </Alert>
                </Box>
              )}
              <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ paddingX: '20px', paddingY: '10px' }}
                >
                  Crear Espacio
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SpaceForm;
