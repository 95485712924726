import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './HomeFilters.scss';

const FilterButton = (props) => {
  const { label } = props;
  return (
    <button
      src="spaces/espacio-1.png"
      alt="Foto 1"
      style={{ width: '100%', height: '110px', border: 'none' }}
      className={'bgGray025'}
    >
      <FlexContainer column className={'gap-10'}>
        <i className={'fas fa-home text-26 fontGray250'} />
        <Label className={'subTitle2 text-11 m-text-10 fontGray250 margt8'}>{label}</Label>
      </FlexContainer>
    </button>
  );
};

const HomeFilters = () => {
  return (
    <PageContainer className={'bgGray025'}>
      <FlexContainer className={'flex-column myFilterCarousel'}>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={10}
          slidesPerView={5}
          className="m-padtb10 t-hide m-hide"
        >
          <SwiperSlide>
            <FilterButton label={'Coworkings'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Estudio'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Jardín'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Oficina'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Terraza'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Sala de Juntas'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Oficina Virtual'} />
          </SwiperSlide>
        </Swiper>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={10}
          slidesPerView={3}
          className="myFilterCarousel m-padtb0 d-hide"
        >
          <SwiperSlide>
            <FilterButton label={'Coworkings'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Estudio'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Jardín'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Oficina'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Terraza'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Sala de Juntas'} />
          </SwiperSlide>
          <SwiperSlide>
            <FilterButton label={'Oficina Virtual'} />
          </SwiperSlide>
        </Swiper>
      </FlexContainer>
    </PageContainer>
  );
};

export default HomeFilters;
