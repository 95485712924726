import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  useCreateUser,
  updateUserMainImage,
  createUser,
} from '@/services/userService';

import { TextField, Grid, Box, Button, Typography, Alert } from '@mui/material';
import { getUserValidationSchema as validationSchema } from '../../../../Validations/ValidationUserSchema';
import { createUserTypeRelationship } from '../../../../services/userTypeRelationshipService';

const UseForm = () => {
  //const { mutate } = useCreateUser();
  const [successMessage, setSuccessMessage] = useState('');
  /*  
  const handleSubmit = async (values, { resetForm }) => {
    console.log(JSON.stringify(values));
    var subtypeRelationshipValues = values?.subtypeRelationship;
    subtypeRelationshipValues.user_id = values?.user_id;
    console.log('subtype', subtypeRelationshipValues);
    try {
      const vqr = await mutate(values);
      await updateUserMainImage(parseInt(values.user_id), values.main_image);
      await createUserTypeRelationship(subtypeRelationshipValues);
      console.log('Espacio creado y/o imagen actualizada con éxito');
      setSuccessMessage('Espacio creado y/o imagen actualizada con éxito');
      console.log(vqr);
      resetForm();
    } catch (error) {
      console.error('Error al crear o actualizar el espacio:', error);
      setSuccessMessage('Error al crear o actualizar el espacio');
    }
  }; */
  const handleSubmit = async (values, { resetForm }) => {
    console.log('Valores enviados:', JSON.stringify(values));

    try {
      // Llamar a la mutación para crear el usuario
      //const createdUser = await mutate(values);
      const createdUser = await createUser(values);
      console.log('asdad kbro', createdUser);

      // Verificar si la respuesta contiene un user_id
      if (!createdUser || !createdUser.user_id) {
        throw new Error('No se recibió un user_id válido al crear el usuario');
      }

      // Obtener el user_id de la respuesta de la mutación
      const userId = createdUser.user_id;
      console.log('User ID obtenido de la respuesta:', userId);

      // Crear la relación de subtipos usando el user_id obtenido de la mutación
      const subtypeRelationshipValues = values?.subtypeRelationship || {};
      subtypeRelationshipValues.user_id = userId; // Asignar el user_id a subtypeRelationship
      console.log('Relación de subtipos:', subtypeRelationshipValues);

      // Actualizar la imagen principal del usuario
      await updateUserMainImage(userId, values.main_image);

      // Crear la relación del tipo de usuario
      await createUserTypeRelationship(subtypeRelationshipValues);

      console.log('Espacio creado y/o imagen actualizada con éxito');
      setSuccessMessage('Espacio creado y/o imagen actualizada con éxito');

      // Resetear el formulario después de una actualización exitosa
      resetForm();
    } catch (error) {
      // Manejo de errores
      console.error('Error al crear o actualizar el espacio:', error);
      setSuccessMessage('Error al crear o actualizar el espacio');
    }
  };

  return (
    <Formik
      initialValues={{
        user_idnumber: '',
        user_name: '',
        user_lastname: '',
        user_email: '',
        user_phone: '',
        main_image: null,
        id_state: 1,
        fecreg: '',
        password: '',
        enabled: 0,
        account_expired: 0,
        credentials_expired: 0,
        account_locked: 0,
        subtypeRelationship: {
          relationship_id: '',
          type_id: 1,
          profile_details: '',
          contact_email: '',
          contact_phone: '',
          state_id: 1,
          fb_url: '',
          ig_url: '',
          ld_url: '',
          tk_url: '',
        },
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors, touched }) => {
        console.log('Valores del formulario:', values);
        console.log('Errores de validación:', errors);
        console.log('Campos tocados:', touched);
        return (
          <Form>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Crear Usuario Gabriel
            </Typography>

            <Box sx={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field name="user_idnumber">
                    {({ field }) => (
                      <TextField
                        label="User ID Number"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.user_idnumber && errors.user_idnumber,
                        )}
                        helperText={
                          touched.user_idnumber && errors.user_idnumber
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="user_name">
                    {({ field }) => (
                      <TextField
                        label="User Name"
                        fullWidth
                        {...field}
                        error={Boolean(touched.user_name && errors.user_name)}
                        helperText={touched.user_name && errors.user_name}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="user_lastname">
                    {({ field }) => (
                      <TextField
                        label="User Lastname"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.user_lastname && errors.user_lastname,
                        )}
                        helperText={
                          touched.user_lastname && errors.user_lastname
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="user_email">
                    {({ field }) => (
                      <TextField
                        label="User Email"
                        type="email"
                        fullWidth
                        {...field}
                        error={Boolean(touched.user_email && errors.user_email)}
                        helperText={touched.user_email && errors.user_email}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="user_phone">
                    {({ field }) => (
                      <TextField
                        label="User Phone"
                        fullWidth
                        {...field}
                        error={Boolean(touched.user_phone && errors.user_phone)}
                        helperText={touched.user_phone && errors.user_phone}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="main_image">
                    {({ field, form }) => (
                      <>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          sx={{ marginTop: '10px' }}
                        >
                          Cargar Imagen
                          <input
                            type="file"
                            hidden
                            onChange={e => {
                              setFieldValue(
                                'main_image',
                                e.currentTarget.files[0],
                              );
                            }}
                            accept="image/*"
                          />
                        </Button>
                        {form.errors.main_image && form.touched.main_image && (
                          <div style={{ color: 'red' }}>
                            {form.errors.main_image}
                          </div>
                        )}
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="fecreg">
                    {({ field }) => (
                      <TextField
                        label="Date Registration"
                        type="date"
                        fullWidth
                        {...field}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={Boolean(touched.fecreg && errors.fecreg)}
                        helperText={touched.fecreg && errors.fecreg}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="password">
                    {({ field }) => (
                      <TextField
                        label="Password"
                        fullWidth
                        {...field}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="enabled">
                    {({ field }) => (
                      <TextField
                        select
                        label="Enabled"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.enabled && errors.enabled)}
                        helperText={touched.enabled && errors.enabled}
                      >
                        <option value="0">No Habilitado</option>
                        <option value="1">Habilitado</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="account_expired">
                    {({ field }) => (
                      <TextField
                        select
                        label="Account Expired"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.account_expired && errors.account_expired,
                        )}
                        helperText={
                          touched.account_expired && errors.account_expired
                        }
                      >
                        <option value="0">No Habilitado</option>
                        <option value="1">Habilitado</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="credentials_expired">
                    {({ field }) => (
                      <TextField
                        select
                        label="Credentials Expired"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.credentials_expired &&
                            errors.credentials_expired,
                        )}
                        helperText={
                          touched.credentials_expired &&
                          errors.credentials_expired
                        }
                      >
                        <option value="0">No Habilitado</option>
                        <option value="1">Habilitado</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="account_locked">
                    {({ field }) => (
                      <TextField
                        select
                        label="Account Locked"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.account_locked && errors.account_locked,
                        )}
                        helperText={
                          touched.account_locked && errors.account_locked
                        }
                      >
                        <option value="0">No Habilitado</option>
                        <option value="1">Habilitado</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.relationship_id">
                    {({ field }) => (
                      <TextField
                        label="Relationship ID"
                        type="number"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.relationship_id &&
                            errors.subtypeRelationship?.relationship_id,
                        )}
                        helperText={
                          touched.subtypeRelationship?.relationship_id &&
                          errors.subtypeRelationship?.relationship_id
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.type_id">
                    {({ field }) => (
                      <TextField
                        select
                        label="Type"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.type_id &&
                            errors.subtypeRelationship?.type_id,
                        )}
                        helperText={
                          touched.subtypeRelationship?.type_id &&
                          errors.subtypeRelationship?.type_id
                        }
                      >
                        <option value="1">GUEST</option>
                        <option value="2">SYSTEM</option>
                        <option value="3">HOST</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="subtypeRelationship.profile_details">
                    {({ field }) => (
                      <TextField
                        label="Type Profile Details"
                        type="text"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.profile_details &&
                            errors.subtypeRelationship?.profile_details,
                        )}
                        helperText={
                          touched.subtypeRelationship?.profile_details &&
                          errors.subtypeRelationship?.profile_details
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  <Field name="subtypeRelationship.contact_email">
                    {({ field }) => (
                      <TextField
                        label="Contact Email"
                        type="email"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.contact_email &&
                            errors.subtypeRelationship?.contact_email,
                        )}
                        helperText={
                          touched.subtypeRelationship?.contact_email &&
                          errors.subtypeRelationship?.contact_email
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.contact_phone">
                    {({ field }) => (
                      <TextField
                        label="Contact Phone"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.contact_phone &&
                            errors.subtypeRelationship?.contact_phone,
                        )}
                        helperText={
                          touched.subtypeRelationship?.contact_phone &&
                          errors.subtypeRelationship?.contact_phone
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.state_id">
                    {({ field }) => (
                      <TextField
                        select
                        label="State"
                        fullWidth
                        {...field}
                        SelectProps={{ native: true }}
                        error={Boolean(
                          touched.subtypeRelationship?.state_id &&
                            errors.subtypeRelationship?.state_id,
                        )}
                        helperText={
                          touched.subtypeRelationship?.state_id &&
                          errors.subtypeRelationship?.state_id
                        }
                      >
                        <option value="2">Inactivo</option>
                        <option value="1">Activo</option>
                      </TextField>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.fb_url">
                    {({ field }) => (
                      <TextField
                        label="Facebook Url"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.fb_url &&
                            errors.subtypeRelationship?.fb_url,
                        )}
                        helperText={
                          touched.subtypeRelationship?.fb_url &&
                          errors.subtypeRelationship?.fb_url
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.ig_url">
                    {({ field }) => (
                      <TextField
                        label="Instagram Url"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.ig_url &&
                            errors.subtypeRelationship?.ig_url,
                        )}
                        helperText={
                          touched.subtypeRelationship?.ig_url &&
                          errors.subtypeRelationship?.ig_url
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.ld_url">
                    {({ field }) => (
                      <TextField
                        label="Linkedin Url"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.ld_url &&
                            errors.subtypeRelationship?.ld_url,
                        )}
                        helperText={
                          touched.subtypeRelationship?.ld_url &&
                          errors.subtypeRelationship?.ld_url
                        }
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field name="subtypeRelationship.tk_url">
                    {({ field }) => (
                      <TextField
                        label="Tik Tok Url"
                        fullWidth
                        {...field}
                        error={Boolean(
                          touched.subtypeRelationship?.tk_url &&
                            errors.subtypeRelationship?.tk_url,
                        )}
                        helperText={
                          touched.subtypeRelationship?.tk_url &&
                          errors.subtypeRelationship?.tk_url
                        }
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              {successMessage && (
                <Box sx={{ marginTop: '20px', textAlign: 'center' }}>
                  <Alert
                    severity={
                      successMessage.includes('Error') ? 'error' : 'success'
                    }
                  >
                    {successMessage}
                  </Alert>
                </Box>
              )}
              <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ paddingX: '20px', paddingY: '10px' }}
                >
                  Crear Usuario
                </Button>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UseForm;
