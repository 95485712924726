import React, { useEffect } from 'react';
import { useProductPreList } from './hooks/ProductPreList.hook';
import ProductPreListView from './views/ProductPreListView';
import LoadingComponent from '@/components/general/Loading/LoadingComponent';

const ProductPreListController = () => {
  const { isLoading, spacesDataFromBackend } = useProductPreList();
  if (isLoading) {
    return <LoadingComponent loading={isLoading} />;
  } else {
    return <ProductPreListView spacesDataFromBackend={spacesDataFromBackend} />;
  }
};

export default ProductPreListController;
