import React, { useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import SpaceCard from '@/components/reusable/SpaceCard/SpaceCard';
import Label from '@/components/form/Label/Label';
import dayjs from 'dayjs';
import './ReservationSuccess.scss';

require('dayjs/locale/es');
dayjs.locale('es');

const SpaceDetailInfo = (props) => {
  const { spaceDetail } = props;
  return (
    <FlexContainer className={'flex-column format100'}>
      <SpaceCard
        spaceInfo={spaceDetail}
        isInlineStyle
        imgUrl={spaceDetail?.main_image}
        spaceTitle={spaceDetail?.space_title}
        spaceDescription={spaceDetail?.space_details}
        price={spaceDetail?.price}
        disableButton
        wifi
        coffee
        parking
        isWhiteBg
      />
    </FlexContainer>
  );
};

const PaymentSection = (props) => {
  const { spaceDetail, formValues } = props;

  const date = dayjs(formValues?.startDate?.$d);
  const formattedDate = date.format('dddd D [de] MMMM [de] YYYY');

  const mainGuestLabel = 'Invitado responsable: ' + formValues?.name;
  const guestLabel = 'Cantidad de invitados: ' + formValues?.guestQuantyty;
  const dateLabel = 'Fecha de reserva: ' + formattedDate;
  const startTime = 'Hora de inicio: ' + formValues?.startTime + ':00 horas';
  const endTime = 'Hora de Fin: ' + formValues?.endTime + ':00 horas';

  return (
    <FlexContainer className={'flex-column text-align-justify justify-around m-padlr10'}>
      <Label className="width100 text-16">
        Estimado/a: {formValues?.name}. Estamos emocionados de que hayas reservado un espacio en{' '}
        {spaceDetail?.space_title} para tu próxima sesión de {spaceDetail?.subtype_title}.
      </Label>
      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />
      <label className={'text-14 m-text-12 bold'}>Datos de la reserva:</label>
      <label className={'text-14 m-text-12 '}>{mainGuestLabel}</label>
      <label className={'text-14 m-text-12 '}>{guestLabel}</label>
      <FlexContainer className="padtb10 bgGray025 m-margtb10 margtb10" />
      <label className={'text-14 m-text-12 '}>{dateLabel}</label>
      <label className={'text-14 m-text-12 '}>{startTime}</label>
      <label className={'text-14 m-text-12 '}>{endTime}</label>
    </FlexContainer>
  );
};

const ReservationSuccess = (props) => {
  const { spaceDetail, formValues } = props;
  const spaceJson = {
    space_id: 10,
    id_relationship: 4,
    space_name:
      'Edificio en el corazón de la Condesa! Un exclusivo espacio de coworking de cuatro niveles, donde la luz natural y el diseño moderno te envuelven en un ambiente perfecto para la productividad y la colaboración. Aquí, la innovación y el diseño se unen para crear un espacio donde la creatividad y la colaboración se elevan al máximo',
    space_title: 'The Club Coworking Condesa',
    subtype_title: 'Coworking',
    space_details_general:
      'The Club Coworking en la Condesa ofrece un elegante espacio de trabajo de cuatro niveles en una de las zonas más vibrantes de Ciudad de México, ideal para profesionales en busca de productividad y networking. Entre sus amenidades se encuentran una terraza multifuncional, balcones privados con vistas verdes, seguridad 24/7, internet, salas de reuniones, manejo de correspondencia y servicio de café. Su diseño combina mármol, madera y luz natural, creando un entorno sofisticado. La flexibilidad de acceso y eventos de comunidad completan la experiencia. Terraza multifuncional: ¡Trabaja, disfruta de una taza de café o realiza llamadas en un ambiente relajado y propicio para hacer networking! Excelente accesibilidad: Ubicado a pocos pasos de estaciones de Metrobús y metro, así como de las principales vías de comunicación de la ciudad.',
    space_details:
      'Exclusivos espacios de coworking de cuatro niveles, donde la luz natural y el diseño moderno te envuelven en un ambiente perfecto para la productividad y la colaboración. Aquí, la innovación y el diseño se unen para crear un espacio donde la creatividad y la colaboración se elevan al máximo.',
    host_capacity: 20,
    pet_friendly: '0',
    coffe_water: '0',
    hourly_rate: '53.37',
    rate_4_hours: '213.50',
    rate_8_hours: '427.00',
    state_name: 'Available',
    parking: '0',
    parking_details: 'Sobre la calles es posible estacionarse usando parquímetro',
    main_image: 'spaces/espacio1-condesa.png',
    house_number: 410,
    space_latitude: '19.41061113855626',
    space_logitude: '-99.17615947584562',
    street_reference: 'A 2 cuadras de Av. Tamaulipas y a 4 cuadras de Av. Michoacán',
    zip_code: 6170,
    is_reserved: '0',
    gmaps_url:
      'https://www.google.com/maps/place/The+Club+Coworking+Condesa/@19.4103733,-99.1787451,17z/data=!3m2!4b1!5s0x85d1ff5d49aa360b:0xf0719e967d6dda4d!4m6!3m5!1s0x85d1ff9b21972a99:0xe1eb913f0acae16!8m2!3d19.4103683!4d-99.1761702!16s%2Fg%2F11y3yf28h0?entry=ttu&g_ep=EgoyMDI0MTExMS4wIKXMDSoASAFQAw%3D%3D',
    space_address: 'Campeche 410, Hipódromo Condesa, Ciudad de México, 06170, México',
  };

  const reservationJson = {
    startDate: {
      $L: 'en',
      $d: '2024-11-20T05:00:00.000Z',
      $y: 2024,
      $M: 10,
      $D: 20,
      $W: 3,
      $H: 0,
      $m: 0,
      $s: 0,
      $ms: 0,
      $x: {},
      $isDayjsObject: true,
    },
    startTime: 10,
    endTime: 14,
    name: 'Mario Enrique Vargas Pino',
    email: 'sistemas@popnest.org',
    phone: '922907079',
    countryCode: '+52',
    guestQuantyty: 1,
  };
  const spaceData = spaceDetail ? spaceDetail : spaceJson;
  const formData = formValues ? formValues : reservationJson;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={''}>
      <FlexContainer className={'flex-column margt72'}>
        <Label type="Subtitle-1 width100 bold margt50 padlr20 m-padlr10 m-margt20 text-26">
          Estimado usuario, se registró correctamente su reserva
        </Label>
        <FlexContainer className={'m-flex-column margt20 margb120 gap-20'}>
          <SpaceDetailInfo spaceDetail={spaceData} formValues={formData} />
          <PaymentSection spaceDetail={spaceData} formValues={formData} />
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
};

export default ReservationSuccess;
