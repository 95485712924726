import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { CommentSection } from 'react-comments-section';
import Rating from 'rating-react';
import ReservationForm from '@/components/general/Forms/ReservationForm/ReservationForm';
import Label from '@/components/form/Label/Label';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './ProductDetails.scss';
import 'react-comments-section/dist/index.css';

const SpaceDetailInfo = (props) => {
  const { spaceDetail } = props;
  const coffee = spaceDetail?.coffe_water === '1' ? true : false;
  const parking = spaceDetail?.parking === '1' ? true : false;
  const data = [
    /*
    {
      userId: '01a',
      comId: '012',
      fullName: 'Riya Negi',
      avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'Hey, Loved your blog! ',
      timestamp: '2024-09-28T10:34:56Z',
      replies: [
        {
          userId: '02a',
          comId: '013',
          userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          fullName: 'Adam Scott',
          avatarUrl: 'https://ui-avatars.com/api/name=Adam&background=random',
          text: 'Thanks! It took me 1 month to finish this project but I am glad it helped out someone!🥰',
          timestamp: '2024-09-28T12:34:56Z',
        },
        {
          userId: '01a',
          comId: '014',
          userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          fullName: 'Riya Negi',
          avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
          text: 'thanks!😊',
          timestamp: '2024-09-28T12:34:56Z',
        },
      ],
    },
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I have a doubt about the 4th point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      timestamp: '2024-09-28T12:34:56Z',
      replies: [],
    },
    */
  ];
  return (
    <FlexContainer className={'flex-column format140 m-format100 m-hide t-hide'}>
      <FlexContainer className={'width100 margt10'}>
        <Label type="Subtitle-1 width100 bold padtb20 text-26">
          {spaceDetail?.subtype_title}: {spaceDetail?.space_title}
        </Label>
      </FlexContainer>
      <FlexContainer className={'width100 margtb20 productPreList d-hide'}>
        <Swiper
          navigation
          autoplay={{
            delay: 10500,
            disableOnInteraction: true,
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          className=""
        >
          <SwiperSlide>
            <img src={spaceDetail?.main_image} alt={''} className={'width100'} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={spaceDetail?.main_image} alt={''} className={'width100'} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={spaceDetail?.main_image} alt={''} className={'width100'} />
          </SwiperSlide>
        </Swiper>
      </FlexContainer>
      <FlexContainer className={'width100 margtb20 m-hide'}>
        <img src={spaceDetail?.main_image} alt={''} className={'width100'} />
      </FlexContainer>
      <FlexContainer className={'width100 margt10'}>
        <FlexContainer
          className={'items-align-start margt10 width100 align-items-center fontGray075 gap-10'}
        >
          {<i className={'fas fa-wifi text-24'} />}
          {coffee && <i className={'fas fa-coffee text-24'} />}
          {parking && <i className={'fas fa-car-side text-24'} />}
        </FlexContainer>
        <FlexContainer className={'flex-column items-align-end margt10 width100'}>
          <Rating value={4} />
        </FlexContainer>
      </FlexContainer>
      <Label type="text-14" className={'width100 margtb20 text-justify'}>
        {spaceDetail?.space_details}
      </Label>
      <CommentSection
        currentUser={{
          currentUserId: '01a',
          currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
          currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          currentUserFullName: 'Riya Negi',
        }}
        commentData={data}
        logIn={{
          onLogin: () => alert('Call login function '),
          signUpLink: 'http://localhost:3001/',
        }}
        placeHolder="Write your comment..."
        onSubmitAction={({}) => console.log('check submit, ', data)}
        currentData={(data) => {
          console.log('current data', data);
        }}
      />
    </FlexContainer>
  );
};

const ProductDetailReservationForm = (props) => {
  const { spaceDetail } = props;
  const navigate = useNavigate();
  const goToCheckout = (values) => {
    navigate('/checkout', { state: { spaceDetail, values } });
  };
  return (
    <FlexContainer className={'flex-column format80 m-format100 margtb20'}>
      <ReservationForm
        className={'bgWhite grayShadow16 border-rad8px padlr20 m-padlr10'}
        spaceDetail={spaceDetail}
        onSubmitFunction={(values) => goToCheckout(values)}
      />
    </FlexContainer>
  );
};

const ProductDetails = (props) => {
  const { spaceDetail } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={''}>
      <FlexContainer className={'m-flex-column margt72 gap-20'}>
        <SpaceDetailInfo spaceDetail={spaceDetail} />
        <ProductDetailReservationForm spaceDetail={spaceDetail} />
      </FlexContainer>
    </PageContainer>
  );
};

export default ProductDetails;
