import React, { useState, useEffect } from 'react';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { useNavigate } from 'react-router-dom';
import PaymentForm from '@/components/general/Forms/PaymentForm/PaymentForm';
import SpaceCard from '@/components/reusable/SpaceCard/SpaceCard';
import Label from '@/components/form/Label/Label';
import './Checkout.scss';

const SpaceDetailInfo = (props) => {
  const { spaceDetail } = props;
  return (
    <FlexContainer className={'flex-column format100'}>
      <SpaceCard
        spaceInfo={spaceDetail}
        isInlineStyle
        imgUrl={spaceDetail?.main_image}
        spaceTitle={spaceDetail?.space_title}
        spaceDescription={spaceDetail?.space_details}
        price={spaceDetail?.price}
        disableButton
        wifi
        coffee
        parking
        isWhiteBg
      />
    </FlexContainer>
  );
};

const PaymentSection = (props) => {
  const { spaceDetail, formValues } = props;
  return (
    <FlexContainer className={'flex-column format100'}>
      <PaymentForm spaceDetail={spaceDetail} formValues={formValues} />
    </FlexContainer>
  );
};

const Checkout = (props) => {
  const { spaceDetail, formValues } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer className={''}>
      <FlexContainer className={'flex-column margt72'}>
        <Label type="Subtitle-1 width100 bold margt50 padlr20 m-padlr10 m-margt20 text-26">
          Realice el pago de su reserva en {spaceDetail?.subtype_title}: {spaceDetail?.space_title}
        </Label>
        <FlexContainer className={'m-flex-column margt20 margb120 gap-20'}>
          <SpaceDetailInfo spaceDetail={spaceDetail} />
          <PaymentSection spaceDetail={spaceDetail} formValues={formValues} />
        </FlexContainer>
      </FlexContainer>
    </PageContainer>
  );
};

export default Checkout;
