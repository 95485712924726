import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import ClipLoader from 'react-spinners/ClipLoader';

const LoadingComponent = (props) => {
  const { isLoading } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FlexContainer className={'flex-column margt100 margb130'}>
      <FlexContainer className={'flex-column margt100 margb130 align-items-center'}>
        <ClipLoader
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default LoadingComponent;
