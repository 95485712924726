import React, { useState, useEffect } from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { CommentSection } from 'react-comments-section';
import { PageContainer } from '@/components/containers/PageContainer/PageContainer';
import Rating from 'rating-react';
import SpaceCard from '@/components/reusable/SpaceCard/SpaceCard';
import SearchAccordion from '@/components/general/SearchAccordion/SearchAccordion';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './ProductPreListView.scss';
import 'react-comments-section/dist/index.css';

const GoogleMapComponent = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC75q_cO4SIMGhQgRJwAIpNfNQ5cXwVXnU',
  });

  const [map, setMap] = React.useState(null);

  const center = {
    lat: 19.4153208,
    lng: -99.181234,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const onLoad = React.useCallback(function callback(map) {
    //const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

const SpaceDetailInfo = (props) => {
  const { closeButton, spaceData } = props;
  const data = [
    /*
    {
      userId: '01a',
      comId: '012',
      fullName: 'Riya Negi',
      avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'Hey, Loved your blog! ',
      timestamp: '2024-09-28T10:34:56Z',
      replies: [
        {
          userId: '02a',
          comId: '013',
          userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          fullName: 'Adam Scott',
          avatarUrl: 'https://ui-avatars.com/api/name=Adam&background=random',
          text: 'Thanks! It took me 1 month to finish this project but I am glad it helped out someone!🥰',
          timestamp: '2024-09-28T12:34:56Z',
        },
        {
          userId: '01a',
          comId: '014',
          userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
          fullName: 'Riya Negi',
          avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
          text: 'thanks!😊',
          timestamp: '2024-09-28T12:34:56Z',
        },
      ],
    },
    {
      userId: '02b',
      comId: '017',
      fullName: 'Lily',
      userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
      text: 'I have a doubt about the 4th point🤔',
      avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
      timestamp: '2024-09-28T12:34:56Z',
      replies: [],
    },
    */
  ];
  const navigate = useNavigate();
  const goToSpaceDetails = () => {
    navigate('/productDetails', { state: { spaceData } });
  };
  const coffee = spaceData?.coffe_water === '1' ? true : false;
  const parking = spaceData?.parking === '1' ? true : false;

  if (spaceData) {
    return (
      <FlexContainer className={'flex-column'}>
        <FlexContainer className={'width100 margt10'}>
          <Label type="Subtitle-1 width100 bold padt10 text-20 m-text-14">
            {spaceData?.space_title}
          </Label>
          <FlexContainer className={'flex-column items-align-end margt10 width100'}>
            <button
              onClick={closeButton}
              className={
                'bgWhite bold border-rad8px borderGray050 grayShadow16 padlr20 padtb10 grayN250'
              }
            >
              Atras <i className="fas fa-arrow-left" />
            </button>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer className={'width100 margtb10 productPreList d-hide'}>
          <Swiper
            navigation
            autoplay={{
              delay: 10500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, Navigation]}
            spaceBetween={30}
            slidesPerView={1}
            className=""
          >
            <SwiperSlide>
              <img src={spaceData?.main_image} alt={''} className={'width100'} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={spaceData?.main_image} alt={''} className={'width100'} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={spaceData?.main_image} alt={''} className={'width100'} />
            </SwiperSlide>
          </Swiper>
        </FlexContainer>
        <FlexContainer className={'width100 margtb20 m-hide'}>
          <img src={spaceData?.main_image} alt={''} className={'width100'} />
        </FlexContainer>
        <FlexContainer className={'width100 margt10'}>
          <FlexContainer
            className={'items-align-start width100 align-items-center fontGray075 gap-10'}
          >
            {<i className={'fas fa-wifi text-20'} />}
            {coffee && <i className={'fas fa-coffee text-20'} />}
            {parking && <i className={'fas fa-car-side text-20'} />}
          </FlexContainer>
          <FlexContainer className={'flex-column items-align-end width100'}>
            <button
              onClick={goToSpaceDetails}
              className={
                'bgPurple4 fontWhite bold border-rad8px borderGray050 grayShadow16 padlr20 padtb10 grayN250'
              }
            >
              Reservar
            </button>
          </FlexContainer>
        </FlexContainer>
        <Label type="text-14" className={'width100 margtb20 text-justify'}>
          {spaceData?.space_details_general}
        </Label>
        <FlexContainer className={'flex-column width100 margt10'}>
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Label type="Subtitle-1" className={'text-14 m-text-16 text-left flex-column'}>
                Coworking
              </Label>
            </AccordionSummary>
            <AccordionDetails>
              <FlexContainer className={'flex-column '}>
                <Label type="text-14" className={'width100 margtb20 text-justify'}>
                  {spaceData?.space_details}
                </Label>
                <Rating value={4} size={'sm'} />
                <button
                  type="submit"
                  onClick={goToSpaceDetails}
                  className={
                    'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-14'
                  }
                >
                  Reserva tu coworking aquí
                </button>
              </FlexContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion className="margt10">
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Label type="Subtitle-1" className={'text-14 m-text-16 text-left flex-column'}>
                Sala de Juntas
              </Label>
            </AccordionSummary>
            <AccordionDetails>
              <FlexContainer className={'flex-column '}>
                <Label type="text-14" className={'width100 margtb20 text-justify'}>
                  {spaceData?.space_details}
                </Label>
                <Rating value={4} size={'sm'} />
                <button
                  type="submit"
                  onClick={goToSpaceDetails}
                  className={
                    'bgPurple4 bold border-rad8px borderGray050 fontWhite grayShadow16 margtb20 padlr40 padtb20 text-14'
                  }
                >
                  Reserva tu sala de juntas aquí
                </button>
              </FlexContainer>
            </AccordionDetails>
          </Accordion>
        </FlexContainer>
        <CommentSection
          currentUser={{
            currentUserId: '01a',
            currentUserImg: 'https://ui-avatars.com/api/name=Riya&background=random',
            currentUserProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
            currentUserFullName: 'Riya Negi',
          }}
          commentData={data}
          logIn={{
            onLogin: () => alert('Call login function '),
            signUpLink: 'http://localhost:3001/',
          }}
          placeHolder="Write your comment..."
          onSubmitAction={({}) => console.log('check submit, ', data)}
          currentData={(data) => {
            console.log('current data', data);
          }}
        />
      </FlexContainer>
    );
  } else {
    return <></>;
  }
};

const ProductPreListView = (props) => {
  const { spacesDataFromBackend } = props;
  const [spaceDetailData, setSpaceDetailData] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const openSpaceDetail = (space) => {
    console.log(JSON.stringify(space));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSpaceDetailData(space);
  };
  const closeSpaceDetail = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setSpaceDetailData(null);
  };
  const containerLeftClass = classNames('flex-column margtb70', {
    format60: spaceDetailData,
    width100: !spaceDetailData,
    't-hide m-hide': spaceDetailData,
  });
  const containerRightClass = classNames(
    'flex-column margtb70 padtb20 padlr20 m-padlr0 t-hide m-hide width100',
    {
      format120: spaceDetailData,
      format100: !spaceDetailData,
    },
  );
  return (
    <FlexContainer className={'flex-column width100'}>
      <SearchAccordion className={'margt70 width100'} />
      <PageContainer className={'margt40'}>
        <FlexContainer className={'m-flex-column padlr40 m-padlr10'}>
          {spaceDetailData && (
            <FlexContainer className={'flex-column margtb70 padlr20 m-padlr0 d-hide'}>
              <SpaceDetailInfo closeButton={closeSpaceDetail} spaceData={spaceDetailData} />
            </FlexContainer>
          )}
          <FlexContainer className={containerLeftClass}>
            {spacesDataFromBackend?.length >= 1 &&
              spacesDataFromBackend.map((space, index) => (
                <SpaceCard
                  spaceInfo={space}
                  isInlineStyle
                  imgUrl={space?.main_image}
                  spaceType={space?.type}
                  spaceTitle={space?.space_title}
                  spaceDescription={space?.space_details}
                  price={space?.price}
                  handleDetailButton={() => openSpaceDetail(space)}
                  priceAndCapacityDetails
                  wifi
                  coffee
                  parking
                  isWhiteBg
                  isMobileStyle
                />
              ))}
            {spacesDataFromBackend?.length >= 1 &&
              spacesDataFromBackend.map((space, index) => (
                <SpaceCard
                  spaceInfo={space}
                  isInlineStyle
                  imgUrl={space?.main_image}
                  spaceType={space?.type}
                  spaceTitle={space?.space_title}
                  spaceDescription={space?.space_details}
                  price={space?.price}
                  handleDetailButton={() => openSpaceDetail(space)}
                  priceAndCapacityDetails
                  wifi
                  coffee
                  parking
                  isWhiteBg
                  isMobileStyle
                />
              ))}
          </FlexContainer>
          <FlexContainer className={containerRightClass}>
            {!spaceDetailData && <GoogleMapComponent />}
            {spaceDetailData && (
              <SpaceDetailInfo closeButton={closeSpaceDetail} spaceData={spaceDetailData} />
            )}
          </FlexContainer>
        </FlexContainer>
      </PageContainer>
    </FlexContainer>
  );
};

export default ProductPreListView;
