import { useQuery, useMutation } from '@tanstack/react-query';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchAllSpaces = async () => {
  const response = await fetch(`${apiUrl}/GET/spaces`);
  if (!response.ok) {
    throw new Error('Error fetching spaces');
  }
  return response.json();
};

export const fetchSpaceById = async (id) => {
  const response = await fetch(`${apiUrl}/GET/spaces/${id}`);
  if (!response.ok) {
    throw new Error('Error fetching space by ID');
  }
  return response.json();
};

export const createSpace = async (spaceData) => {
  const response = await fetch(`${apiUrl}/POST/spaces`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(spaceData),
  });

  if (!response.ok) {
    throw new Error('Error creating space');
  }
  return response.json();
};

export const updateSpace = async ({ id, spaceData }) => {
  const response = await fetch(`${apiUrl}/PUT/spaces/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(spaceData),
  });
  if (!response.ok) {
    throw new Error('Error updating space');
  }
  return response.json();
};

export const updateSpaceMainImage = async (spaceId, mainImageFile) => {
  const formData = new FormData();
  formData.append('main_image', mainImageFile);
  formData.append('space_id', spaceId);

  for (const [key, value] of formData.entries()) {
    console.log(key, value);
  }

  const response = await fetch(`${apiUrl}/POST/spaces/update_main_image`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Error updating space main image');
  }
  return response.json();
};

export const deleteSpace = async (id) => {
  const response = await fetch(`${apiUrl}/PUT/delete_spaces/${id}`, {
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error('Error deleting space');
  }
  return response.json();
};

export const deleteSpacesSeveral = async (spaceIds) => {
  const response = await fetch(`${apiUrl}/PUT/delete_spaces_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ spaceIds }),
  });

  if (!response.ok) {
    throw new Error('Error deleting multiple spaces');
  }
  return response.json();
};

export const enabledSpacesSeveral = async (spaceIds) => {
  const response = await fetch(`${apiUrl}/PUT/enabled_spaces_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ spaceIds }),
  });

  if (!response.ok) {
    throw new Error('Error enabling multiple spaces');
  }
  return response.json();
};

export const disabledSpacesSeveral = async (spaceIds) => {
  const response = await fetch(`${apiUrl}/PUT/disabled_spaces_several`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ spaceIds }),
  });

  if (!response.ok) {
    throw new Error('Error disabling multiple spaces');
  }
  return response.json();
};

export const useFetchAllSpaces = () => {
  return useQuery({
    queryKey: ['spaces'],
    queryFn: fetchAllSpaces,
  });
};

export const useFetchSpaceById = (id) => {
  return useQuery({
    queryKey: ['space', id],
    queryFn: () => fetchSpaceById(id),
    enabled: !!id,
  });
};

export const useCreateSpace = () => {
  return useMutation({
    mutationFn: createSpace,
    onSuccess: () => {
      console.log('Space created successfully');
    },
    onError: (error) => {
      console.error('Error creating space:', error);
    },
  });
};

export const useUpdateSpaceMainImage = () => {
  return useMutation({
    mutationFn: (data) => updateSpaceMainImage(data.spaceId, data.mainImageFile),
    onSuccess: () => {
      console.log('Space main image updated successfully');
    },
    onError: (error) => {
      console.error('Error updating space main image:', error);
    },
  });
};

export const useUpdateSpace = () => {
  return useMutation({
    mutationFn: updateSpace,
    onSuccess: () => {
      console.log('Space updated successfully');
    },
    onError: (error) => {
      console.error('Error updating space:', error);
    },
  });
};

export const useDeleteSpace = () => {
  return useMutation({
    mutationFn: deleteSpace,
    onSuccess: () => {
      console.log('Space deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting space:', error);
    },
  });
};

export const useDeleteSpacesSeveral = () => {
  return useMutation({
    mutationFn: deleteSpacesSeveral,
    onSuccess: () => {
      console.log('Multiple spaces deleted successfully');
    },
    onError: (error) => {
      console.error('Error deleting multiple spaces:', error);
    },
  });
};

export const useEnabledSpacesSeveral = () => {
  return useMutation({
    mutationFn: enabledSpacesSeveral,
    onSuccess: () => {
      console.log('Multiple spaces enabled successfully');
    },
    onError: (error) => {
      console.error('Error enabling multiple spaces:', error);
    },
  });
};

export const useDisabledSpacesSeveral = () => {
  return useMutation({
    mutationFn: disabledSpacesSeveral,
    onSuccess: () => {
      console.log('Multiple spaces disabled successfully');
    },
    onError: (error) => {
      console.error('Error disabling multiple spaces:', error);
    },
  });
};
