import React from 'react';
import { FlexContainer } from '@/components/containers/FlexContainer/FlexContainer';
import Label from '@/components/form/Label/Label';
import classNames from 'classnames';

const SpaceCardFooter = (props) => {
  const { wifi, coffee, parking, handleDetailButton, disableButton } = props;
  return (
    <FlexContainer className={'width100 margt10'}>
      <FlexContainer
        className={'items-align-start margt10 width100 align-items-center fontGray075 gap-10'}
      >
        {wifi && <i className={'fas fa-wifi text-18'} />}
        {coffee && <i className={'fas fa-coffee text-18'} />}
        {parking && <i className={'fas fa-car-side text-18'} />}
      </FlexContainer>
      {!disableButton && (
        <FlexContainer className={'flex-column items-align-end margt10 width100'}>
          <button
            onClick={handleDetailButton}
            className={
              'bgWhite bold border-rad8px borderGray050 grayShadow16 padlr20 padtb10 grayN250 text-12'
            }
          >
            Ver más
          </button>
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

const SpaceCard = (props) => {
  const {
    spaceInfo,
    isMobileStyle,
    wifi,
    handleDetailButton,
    isInlineStyle,
    isWhiteBg,
    priceAndCapacityDetails,
    disableButton,
  } = props;

  const containerClass = classNames('grayShadow16 border-rad8px padlr20 padtb20', {
    'gap-20 m-flex-column': isInlineStyle,
    'flex-column justify-between format100': !isInlineStyle,
    margtb20: isMobileStyle,
    bgWhite: isWhiteBg,
  });

  const textContainerClass = classNames('flex-column width100', {
    'justify-around': isInlineStyle,
  });

  let imageItemStyle = {};

  if (isInlineStyle) {
    imageItemStyle = isMobileStyle ? { width: '100%' } : { width: '100%' };
  } else {
    imageItemStyle = isMobileStyle
      ? { height: '300px', width: '100%' }
      : { width: '100%', height: '300px' };
  }

  const titleStyle = isInlineStyle ? 'bold text-18 padb30' : 'bold text-18 padtb30';

  const truncateDescription = (description, limit) => {
    if (description.length > limit) {
      return description.substring(0, limit) + '...';
    }
    return description;
  };

  const coffee = spaceInfo?.coffe_water === '1' ? true : false;
  const parking = spaceInfo?.parking === '1' ? true : false;

  return (
    <FlexContainer className={containerClass}>
      {spaceInfo?.subtype_title && (
        <span
          className={
            'bgPurple4 text-14 bold border-rad60px fontWhite padt8 padb6 padlr10 pos-abs margt10 marglr10'
          }
        >
          {spaceInfo?.subtype_title}
        </span>
      )}
      <FlexContainer className={'width100'}>
        <img
          src={spaceInfo?.main_image}
          alt={spaceInfo?.space_name}
          style={imageItemStyle}
          className={''}
        />
      </FlexContainer>
      <FlexContainer className={textContainerClass}>
        <Label className={titleStyle}>{truncateDescription(spaceInfo?.space_title, 40)}</Label>
        <Label type="text-14">{truncateDescription(spaceInfo?.space_details, 80)}</Label>
        {priceAndCapacityDetails && (
          <>
            <FlexContainer className={'width100 padt30'}>
              <FlexContainer className={'width100'}>
                <Label type="text-14 bold">Capacidad:</Label>
              </FlexContainer>
              <FlexContainer className={'width100 justify-end'}>
                <Label type="text-16">{spaceInfo?.host_capacity} Personas</Label>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer className={'width100 padtb20'}>
              <FlexContainer className={'width100'}>
                <Label type="text-14 bold">Precio:</Label>
              </FlexContainer>
              <FlexContainer className={'width100 justify-end'}>
                <Label type="text-16">${spaceInfo?.hourly_rate} MX/hora</Label>
              </FlexContainer>
            </FlexContainer>
          </>
        )}
        <SpaceCardFooter
          wifi={wifi}
          coffee={coffee}
          parking={parking}
          handleDetailButton={handleDetailButton}
          disableButton={disableButton}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default SpaceCard;
